var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%, width: 100%" } },
    [
      _c(
        "b-modal",
        {
          attrs: {
            size: "xl",
            title: _vm.labelTitle,
            "footer-class": "footerClass",
            id: _vm.localId,
            "no-close-on-backdrop": "",
            "content-class": "shadow",
            "modal-class": "calendar-modal",
            scrollable: "",
          },
          on: {
            hidden: function ($event) {
              return _vm.$emit("update:show", false)
            },
          },
          scopedSlots: _vm._u([
            {
              key: "modal-footer",
              fn: function ({ cancel }) {
                return [
                  _c(
                    "b-button",
                    {
                      attrs: {
                        disabled: _vm.disableSave,
                        size: "sm",
                        variant: "success",
                      },
                      on: { click: _vm.ok },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.ok")))]
                  ),
                  _c(
                    "b-button",
                    {
                      attrs: { size: "sm", variant: "danger" },
                      on: {
                        click: function ($event) {
                          return cancel()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("button.cancel")))]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.state.modalShow,
            callback: function ($$v) {
              _vm.$set(_vm.state, "modalShow", $$v)
            },
            expression: "state.modalShow",
          },
        },
        [
          _c(
            "b-alert",
            {
              attrs: {
                variant: "danger",
                dismissible: "",
                show: _vm.showError,
              },
              on: { dismissed: _vm.dismissAlert },
            },
            [
              _c("font-awesome-icon", {
                attrs: { icon: ["fas", "triangle-exclamation"] },
              }),
              _vm._v("  " + _vm._s(_vm.alertMsg) + " "),
              _c(
                "ul",
                { staticClass: "mb-0", attrs: { show: _vm.showErrorDetail } },
                [
                  _vm._l(_vm.alertMsgDetails, function (item, index) {
                    return [_c("li", { key: index }, [_vm._v(_vm._s(item))])]
                  }),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            "ul",
            { staticClass: "day-list mb-3 pl-0" },
            [
              _vm._l(_vm.tasks, function (task, index) {
                return [
                  _c(
                    "b-row",
                    { key: index },
                    [
                      _c(
                        "b-col",
                        { attrs: { cols: "12", xl: "4" } },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "activity.field.startTime"
                                        ),
                                        "label-for": "activity-startDate",
                                      },
                                    },
                                    [
                                      _c("b-form-datepicker", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          "today-button": "",
                                          "reset-button": "",
                                          "close-button": "",
                                          "hide-header": "",
                                          "label-today-button":
                                            _vm.$t("date.today"),
                                          "label-reset-button":
                                            _vm.$t("date.reset"),
                                          "label-close-button":
                                            _vm.$t("date.close"),
                                          "today-button-variant": "primary",
                                          "reset-button-variant": "danger",
                                          "close-button-variant": "secondary",
                                          boundary: "viewport",
                                          "popper-opts": {
                                            positionFixed: true,
                                          },
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.dateTimeInputHandler(
                                              $event,
                                              "startDate",
                                              index
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function ({}) {
                                                return [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "calendar-days",
                                                      ],
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: task.startDate,
                                          callback: function ($$v) {
                                            _vm.$set(task, "startDate", $$v)
                                          },
                                          expression: "task.startDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("Timepicker", {
                                    attrs: { locale: "en" },
                                    on: {
                                      input: function ($event) {
                                        return _vm.dateTimeInputHandler(
                                          $event,
                                          "startTime",
                                          index
                                        )
                                      },
                                    },
                                    model: {
                                      value: task.startTime,
                                      callback: function ($$v) {
                                        _vm.$set(task, "startTime", $$v)
                                      },
                                      expression: "task.startTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", xl: "4" } },
                        [
                          _c(
                            "b-row",
                            { staticClass: "mb-3" },
                            [
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "b-form-group",
                                    {
                                      attrs: {
                                        label: _vm.$t(
                                          "activity.field.closeTime"
                                        ),
                                        "label-for": "activity-closeDate",
                                      },
                                    },
                                    [
                                      _c("b-form-datepicker", {
                                        staticClass: "mb-2",
                                        attrs: {
                                          "today-button": "",
                                          "reset-button": "",
                                          "close-button": "",
                                          "hide-header": "",
                                          "label-today-button":
                                            _vm.$t("date.today"),
                                          "label-reset-button":
                                            _vm.$t("date.reset"),
                                          "label-close-button":
                                            _vm.$t("date.close"),
                                          "today-button-variant": "primary",
                                          "reset-button-variant": "danger",
                                          "close-button-variant": "secondary",
                                          boundary: "viewport",
                                          "popper-opts": {
                                            positionFixed: true,
                                          },
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.dateTimeInputHandler(
                                              $event,
                                              "closeDate",
                                              index
                                            )
                                          },
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "button-content",
                                              fn: function ({}) {
                                                return [
                                                  _c("font-awesome-icon", {
                                                    attrs: {
                                                      icon: [
                                                        "far",
                                                        "calendar-days",
                                                      ],
                                                    },
                                                  }),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                        model: {
                                          value: task.closeDate,
                                          callback: function ($$v) {
                                            _vm.$set(task, "closeDate", $$v)
                                          },
                                          expression: "task.closeDate",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("Timepicker", {
                                    on: {
                                      input: function ($event) {
                                        return _vm.dateTimeInputHandler(
                                          $event,
                                          "closeTime",
                                          index
                                        )
                                      },
                                    },
                                    model: {
                                      value: task.closeTime,
                                      callback: function ($$v) {
                                        _vm.$set(task, "closeTime", $$v)
                                      },
                                      expression: "task.closeTime",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "b-col",
                        { attrs: { cols: "12", xl: "4" } },
                        [
                          _c(
                            "b-form-group",
                            {
                              attrs: {
                                label: _vm.$t("activity.field.duration"),
                                "label-for": "activity-duration",
                              },
                            },
                            [
                              _c(
                                "b-input-group",
                                [
                                  _c("b-form-input", {
                                    attrs: {
                                      id: "activity-duration",
                                      type: "text",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.dateTimeInputHandler(
                                          $event,
                                          "duration",
                                          index
                                        )
                                      },
                                      keydown: function ($event) {
                                        return _vm.onDurationKeyDown(
                                          $event,
                                          index
                                        )
                                      },
                                      keyup: function ($event) {
                                        return _vm.onDurationKeyUp(
                                          $event,
                                          index
                                        )
                                      },
                                    },
                                    model: {
                                      value: task.value,
                                      callback: function ($$v) {
                                        _vm.$set(task, "value", $$v)
                                      },
                                      expression: "task.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-inline-block mr-1" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    id: `SPLIT_TASK_ADD_${index}`,
                                    variant: "secondary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.addEntry(index)
                                    },
                                  },
                                },
                                [
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "plus"] },
                                  }),
                                  _c("b-popover", {
                                    attrs: {
                                      target: `SPLIT_TASK_ADD_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t("button.add"),
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "d-inline-block mr-1" },
                            [
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    id: `SPLIT_TASK_DELETE_${index}`,
                                    disabled: index === 0,
                                    variant: "secondary",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeEntry(index)
                                    },
                                  },
                                },
                                [
                                  _c("b-popover", {
                                    attrs: {
                                      target: `SPLIT_TASK_DELETE_${index}`,
                                      placement: "top",
                                      boundary: "viewport",
                                      triggers: "hover",
                                      content: _vm.$t("button.delete"),
                                    },
                                  }),
                                  _c("font-awesome-icon", {
                                    attrs: { icon: ["far", "trash-can"] },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
      _c("TaskDateTimeDurationCalculation", {
        attrs: {
          show: _vm.state.durationCalculationShow,
          resizeMode: _vm.durationCalculation.resizeMode,
          defaultActionForNonWorkPrompt: null,
          trigger: _vm.durationCalculation.trigger,
          startDateStr: _vm.durationCalculation.startDateStr,
          startTimeStr: _vm.durationCalculation.startTimeStr,
          closeDateStr: _vm.durationCalculation.closeDateStr,
          closeTimeStr: _vm.durationCalculation.closeTimeStr,
          durationDisplay: _vm.durationCalculation.durationDisplay,
          calendar: _vm.durationCalculation.calendar,
          projectScheduleFromStart:
            _vm.durationCalculation.projScheduleFromStart,
          activityAutoScheduleMode:
            _vm.durationCalculation.activityAutoScheduleMode,
          constraintType: _vm.durationCalculation.constraintType,
          constraintDateStr: _vm.durationCalculation.constraintDateStr,
          lockDuration: _vm.durationCalculation.lockDuration,
          oldDateStr: _vm.durationCalculation.oldDateStr,
          oldTimeStr: _vm.durationCalculation.oldTimeStr,
          oldConstraintType: _vm.durationCalculation.oldConstraintType,
          oldConstraintDateStr: _vm.durationCalculation.oldConstraintDateStr,
          skipOutOfProjectDateCheck:
            _vm.durationCalculation.skipOutOfProjectDateCheck,
          durationConversionOpts: _vm.durationConversionOpts,
        },
        on: {
          "update:show": function ($event) {
            return _vm.$set(_vm.state, "durationCalculationShow", $event)
          },
          "update:calendar": function ($event) {
            return _vm.$set(_vm.durationCalculation, "calendar", $event)
          },
          success: _vm.durationCalculationOk,
          cancel: _vm.durationCalculationCancel,
          skip: _vm.durationCalculationCancel,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }